<template>
  <div class="most-container">
    <el-tabs type="border-card" v-model="tabName">
      <el-tab-pane label="登录日志" name="first">
        <login-log/>
      </el-tab-pane>
      <el-tab-pane label="操作日志" name="second">
        <operate-log/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LoginLog from '@/views/manage-page/audit/pages/logs/LoginLog'
import OperateLog from '@/views/manage-page/audit/pages/logs/OperateLog'
export default {
  name: 'AuditLog',
  components: {
    LoginLog,
    OperateLog
  },
  data() {
    return {
      tabName: 'first',
      searchForm: {
        sysModule: '',
        opUser: '',
        opType: '',
        reqType: '',
        opStatus: '',
        opTime: []
      },
      tableLoading: false,
      tableList: [{}]
    }
  },
  methods: {
    queryUserList() {

    },
    resetBtn() {

    },
    addDialogOpen() {

    }
  }
}
</script>

<style scoped lang="scss">
.most-container{
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
</style>
